import { Stack } from '@mui/material';
import FormApplicationHeader from './header.form-application';
import FormApplicationFooter from './footer.form-application';
import FormApplicationIntroContainer from './intro/container.intro.form-application';
import { useFormApplication } from './provider.form-application';
import FormApplicationInterviewContainer from './interview/container.interview.form-application';

export default function FormApplicationContainer() {
  const { formData } = useFormApplication();

  return (
    <Stack
      sx={{
        width: 1,
        height: 1,
      }}
    >
      <FormApplicationHeader />

      <Stack
        bgcolor={(theme) => theme.palette.background.neutral}
        sx={{
          flex: 1,
          overflow: 'auto',
        }}
      >
        {formData ? (
          <FormApplicationInterviewContainer />
        ) : (
          <FormApplicationIntroContainer />
        )}
      </Stack>

      <FormApplicationFooter />
    </Stack>
  );
}
