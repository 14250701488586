import { Stack, Typography } from '@mui/material';
import { CustomAvatar } from '@core/ui/components/custom-avatar';
import { useFormApplication } from './provider.form-application';

interface Props {
  mode: 'none' | 'logo' | 'header' | 'full';
}

export default function FormApplicationFormInfo({ mode }: Props) {
  const { form } = useFormApplication();

  if (mode === 'none') {
    return <Stack />;
  }

  return (
    <Stack spacing={1} direction="row" alignItems="center">
      {mode === 'full' || mode === 'logo' ? (
        <CustomAvatar
          src={form.logoUrl}
          sx={{
            width: 36,
            height: 36,
            border: `2px solid ${form.primaryColor}`,
          }}
        />
      ) : null}
      {mode === 'full' || mode === 'header' ? (
        <Stack spacing={0}>
          <Typography variant="subtitle2" lineHeight={1.3}>
            {form.name}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {form.workspaceName}
          </Typography>
        </Stack>
      ) : null}
    </Stack>
  );
}
