import { AxiosError } from 'axios';
import NotFoundError from './NotFoundError';
import InternalError from './InternalError';
import { mapErrorToErrorType, ErrorType } from './utils';

type Props = {
  error?: Error | AxiosError;
  errorType?: ErrorType;
};

export default function ErrorMapper({ error, errorType }: Props) {
  let finalErrorType: ErrorType = errorType || 'internal';
  if (error) {
    finalErrorType = mapErrorToErrorType(error);
  }

  switch (finalErrorType) {
    case 'notFound':
      return <NotFoundError />;
    default:
      return <InternalError />;
  }
}
