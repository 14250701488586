import { Card, Stack, Typography } from '@mui/material';
import { useLocales } from '@core/i18n';
import { useFormApplication } from '../provider.form-application';
import FormApplicationIntroDescription from './description.intro.form-application';

export default function FormApplicationIntroContainer() {
  const { translate } = useLocales();
  const { form } = useFormApplication();

  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      sx={{
        width: 1,
        my: 4,
      }}
    >
      <Stack maxWidth={480} width={1} spacing={2} px={2}>
        <Card
          variant="outlined"
          sx={{
            width: 1,
          }}
        >
          <FormApplicationIntroDescription />
        </Card>

        <Stack>
          <Typography
            variant="body2"
            fontSize={10}
            sx={{ wordWrap: 'break-word', whiteSpace: 'pre-wrap' }}
          >
            {translate('features.formApplication.bowldDisclaimer', {
              name: form.workspaceName,
            })}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
}
