import { Stack, Typography, Link } from '@mui/material';
import { CONSTANTS, ROUTES } from '@core/config';
import { CustomAvatar } from '@core/ui/components/custom-avatar';

export default function FormApplicationFooter() {
  return (
    <Stack
      spacing={1}
      direction="row"
      justifyContent="center"
      alignItems="center"
      bgcolor={(theme) => theme.palette.common.white}
      height={38}
      width={1}
      borderTop={(theme) => `1px solid ${theme.palette.divider}`}
    >
      <CustomAvatar
        src={CONSTANTS.MEDIAS.defaultLogoUrl}
        sx={{
          width: 20,
          height: 20,
        }}
      />

      <Typography
        variant="caption"
        sx={{
          textAlign: 'center',
        }}
      >
        <Link href={ROUTES.landing} target="_blank" underline="none">
          {`Powered by Bowld`}
        </Link>
      </Typography>
    </Stack>
  );
}
