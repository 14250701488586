import { ReactNode } from 'react';
import { Stack, Typography } from '@mui/material';

interface Props {
  title: string;
  subtitle?: string;
  rightItem?: ReactNode;
  content: ReactNode;
}

export default function FormItemContainer({
  title,
  subtitle,
  rightItem,
  content,
}: Props) {
  return (
    <Stack width={1} spacing={1}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={1}
      >
        <Stack>
          {title.length > 0 ? (
            <Typography variant="caption">{title}</Typography>
          ) : null}
          {subtitle ? (
            <Typography
              variant="body2"
              color={(theme) => theme.palette.text.disabled}
            >
              {subtitle}
            </Typography>
          ) : null}
        </Stack>
        {rightItem}
      </Stack>
      {content}
    </Stack>
  );
}
