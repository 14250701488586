import { Stack, Alert, Button } from '@mui/material';
import { alpha } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';
import { useLocales } from '@core/i18n';

interface Props {
  title: string;
  loading: boolean;
  color?: string;
  isLarge?: boolean;
  isDirty?: boolean;
  error?: string;
  onBackClick?: () => void;
}

export default function FormButton({
  title,
  loading,
  color,
  isLarge,
  isDirty,
  error,
  onBackClick,
}: Props) {
  const { translate } = useLocales();

  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      display="flex"
      spacing={2}
    >
      {!!error && (
        <Alert severity="warning" variant="filled">
          {error}
        </Alert>
      )}

      <Stack
        spacing={2}
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        {onBackClick ? (
          <Button variant="outlined" onClick={onBackClick}>
            {translate('common.back')}
          </Button>
        ) : null}

        <LoadingButton
          type="submit"
          variant="contained"
          size={isLarge ? 'large' : 'small'}
          loading={loading}
          disabled={isDirty !== undefined ? !isDirty : false}
          sx={{
            backgroundColor: color,
            '&:hover': color
              ? {
                  backgroundColor: alpha(color, 0.8),
                }
              : undefined,
          }}
        >
          {title}
        </LoadingButton>
      </Stack>
    </Stack>
  );
}
