import FormButton from '@core/ui/components/form-button';
import { Box, Stack, Typography } from '@mui/material';
import { ReactNode } from 'react';

interface Props {
  title?: string;
  loading: boolean;
  removeCtaSpacing?: boolean;
  cta?: string;
  ctaColor?: string;
  isLargeCta?: boolean;
  isStickyBottomCta?: boolean;
  isDirty?: boolean;
  error?: string;
  children: ReactNode;
  onBackClick?: () => void;
}

export default function FormContainer({
  title,
  loading,
  removeCtaSpacing,
  cta,
  ctaColor,
  isLargeCta,
  isStickyBottomCta,
  isDirty,
  error,
  children,
  onBackClick,
}: Props) {
  return (
    <Stack
      spacing={2}
      sx={{
        p: 0,
        backgroundColor: (theme) => theme.palette.common.white,
        borderRadius: (theme) => `${theme.shape.borderRadius}px`,
      }}
    >
      {title ? <Typography variant="overline">{title}</Typography> : undefined}

      <Stack spacing={removeCtaSpacing ? 0 : 4}>
        <Stack spacing={2}>{children}</Stack>

        {cta ? (
          <Box
            sx={
              isStickyBottomCta
                ? {
                    position: 'sticky',
                    bottom: -16,
                    background: 'white',
                    p: 3,
                  }
                : {}
            }
          >
            <Stack
              spacing={2}
              direction="row"
              alignItems="center"
              justifyContent="center"
            >
              <FormButton
                title={cta}
                loading={loading}
                isLarge={isLargeCta}
                color={ctaColor}
                error={error}
                isDirty={isDirty}
                onBackClick={onBackClick}
              />
            </Stack>
          </Box>
        ) : undefined}
      </Stack>
    </Stack>
  );
}
