import { Typography, Stack } from '@mui/material';
import { SeverErrorIllustration } from '@core/ui/components/illustrations';
import { useLocales } from '@core/i18n';

export default function InternalError() {
  const { translate } = useLocales();

  return (
    <Stack
      sx={{
        py: 12,
        px: 2,
        m: 'auto',
        maxWidth: 400,
        minHeight: '100vh',
        textAlign: 'center',
        justifyContent: 'center',
      }}
    >
      <Typography variant="h3" paragraph>
        {`${translate('errors.internal.title')}`}
      </Typography>

      <Typography sx={{ color: 'text.secondary' }}>
        {`${translate('errors.internal.subtitle')}`}
      </Typography>

      <SeverErrorIllustration sx={{ height: 260, my: { xs: 5, sm: 10 } }} />
    </Stack>
  );
}
