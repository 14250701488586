import { isAxiosError } from 'axios';

export type ErrorType = 'notFound' | 'forbidden' | 'internal';

export const mapErrorToErrorType = (error: Error): ErrorType => {
  if (!isAxiosError(error)) {
    return 'internal';
  }

  if (error.response?.status === 403) {
    return 'forbidden';
  }

  if (error.response?.status === 404) {
    return 'notFound';
  }

  return 'internal';
};
