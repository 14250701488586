import {
  createContext,
  useMemo,
  ReactNode,
  useContext,
  useState,
  useCallback,
} from 'react';
import { FormPublic, ContactInformation } from '@core/@types/forms';
import { startApplication as startApplicationRequest } from '@core/services/forms';

interface FormData {
  contact: ContactInformation;
  respondentId: string;
}

interface Props {
  children: ReactNode;
  form: FormPublic;
  isPreview: boolean;
}
interface ContextState {
  form: FormPublic;
  isPreview: boolean;
  formData?: FormData;
  startApplication: (contact: ContactInformation) => Promise<void>;
}

export const Context = createContext<ContextState | null>(null);

export const useFormApplication = () => {
  const context = useContext(Context);

  if (!context)
    throw new Error(
      'useFormApplication context must be used inside FormRepliesMatrixProvider'
    );

  return context;
};

export default function FormApplicationProvider({
  children,
  form,
  isPreview,
}: Props) {
  const [formData, setFormData] = useState<FormData>();

  const startApplication = useCallback(
    async (contact: ContactInformation) => {
      if (!contact.email) {
        return;
      }

      const { respondentId } = await startApplicationRequest(
        form.id,
        contact.email,
        isPreview
      );
      setFormData({
        contact,
        respondentId,
      });
    },
    [form.id, isPreview]
  );

  const memoizedValue = useMemo(
    () => ({
      form,
      isPreview,
      formData,
      startApplication,
    }),
    [form, isPreview, formData, startApplication]
  );

  return <Context.Provider value={memoizedValue}>{children}</Context.Provider>;
}
