import { useFormApplication } from '../provider.form-application';

export default function FormApplicationInterviewContainer() {
  const { formData, form, isPreview } = useFormApplication();
  if (!formData) {
    return null;
  }

  const videoAskParams = new URLSearchParams();
  videoAskParams.append('form_id', form.id);
  videoAskParams.append('respondent_id', formData.respondentId);
  videoAskParams.append('first_name', formData.contact.firstName || '');
  videoAskParams.append('last_name', formData.contact.lastName || '');
  videoAskParams.append('email', formData.contact.email || '');
  videoAskParams.append('phone_number', formData.contact.phoneNumber || '');
  videoAskParams.append('instagram', formData.contact.instagram || '');
  videoAskParams.append('linkedin', formData.contact.linkedIn || '');
  if (isPreview) {
    videoAskParams.append('preview', '');
    videoAskParams.append('hidepreviewbanner', '');
  }

  return (
    <iframe
      title="apply"
      src={`${form.applicationUrl}?${videoAskParams.toString()}`}
      allow="camera *; microphone *; autoplay *; encrypted-media *; fullscreen *; display-capture *;"
      width="100%"
      height="100%"
      style={{
        border: 'none',
      }}
    />
  );
}
