import useSWR from 'swr';
import {
  AddFormInterviewStepRequest,
  EditFormInterviewIntroRequest,
  EditFormInterviewOutroRequest,
  EditFormInterviewStepRequest,
  Form,
  FormAnalytics,
  FormInterviewResponse,
  FormInterviewStepResponse,
  FormPublic,
  FormResponse,
  FormStartApplicationResponse,
  FormType,
  GetFormApplicantsReviewReportResponse,
  GetFormInterviewResponse,
  GetQuestionExpectationSuggestionResponse,
  UpdateFormInterviewStepResponse,
  UpdateFormRequest,
} from '@core/@types/forms';
import axios, { fetcher } from '@core/utils/axios';

export const useGetFormsByWorkspaceId = (workspaceId?: string) => {
  const { data, error, isLoading, mutate } = useSWR<{
    forms: Form[];
  }>(workspaceId ? `/forms?workspaceId=${workspaceId}` : null, fetcher);

  return {
    forms: data?.forms,
    isLoading,
    error: !data?.forms && error ? error : undefined,
    mutate,
  };
};

export const useGetFormById = (id: string) => {
  const { data, error, isLoading, isValidating, mutate } = useSWR<{
    form: Form;
  }>(`/forms/${id}`, fetcher);

  return {
    form: data?.form,
    isLoading,
    isValidating,
    error: !data?.form && error ? error : undefined,
    mutate,
  };
};

export const useGetFormAnalyticsById = (id: string) => {
  const { data, error, isLoading, isValidating, mutate } =
    useSWR<FormAnalytics>(`/forms/${id}/analytics`, fetcher);

  return {
    analytics: data,
    isLoading,
    isValidating,
    error: !data && error ? error : undefined,
    mutate,
  };
};

export const getFormPublicById = async (
  formId: string,
  preview: boolean
): Promise<FormPublic> => {
  const response = await axios.get(
    `/forms/${formId}/public${preview ? '?preview=1' : ''}`
  );
  return response.data.form;
};

export const getFormApplicantsReviewReport = async (
  formId: string,
  formApplicantIds: string[]
): Promise<GetFormApplicantsReviewReportResponse> => {
  const response = await axios.post(
    `/forms/${formId}/review-report`,
    {
      formApplicantIds,
    },
    {
      timeout: 120_000,
    }
  );
  return response.data;
};

export const createForm = async (
  workspaceId: string,
  type: FormType,
  name: string,
  languageCode: string,
  description: string
): Promise<FormResponse> => {
  const response = await axios.post(
    `/forms`,
    {
      workspaceId,
      type,
      name,
      description,
      languageCode,
    },
    {
      timeout: 120_000,
    }
  );
  return response.data;
};

export const deleteById = async (formId: string): Promise<void> => {
  return axios.delete(`/forms/${formId}`);
};

export const cloneById = async (formId: string): Promise<FormResponse> => {
  const response = await axios.post(`/forms/${formId}/clone`);
  return response.data;
};

export const updateForm = async (
  formId: string,
  request: UpdateFormRequest
): Promise<FormResponse> => {
  const response = await axios.put(`/forms/${formId}`, request);
  return response.data.form;
};

export const startApplication = async (
  formId: string,
  email: string,
  preview: boolean
): Promise<FormStartApplicationResponse> => {
  const response = await axios.post(`/forms/${formId}/start-application`, {
    email,
    preview,
  });
  return response.data;
};

export const useGetFormInterviewById = (formId: string) => {
  const { data, error, isLoading, isValidating, mutate } =
    useSWR<GetFormInterviewResponse>(`/forms/${formId}/interviews`, fetcher);

  return {
    interview: data?.interview,
    isLoading,
    isValidating,
    error: !data?.interview && error ? error : undefined,
    mutate,
  };
};

export const getFormInterviewById = async (
  formId: string
): Promise<GetFormInterviewResponse> => {
  const response = await axios.get(`/forms/${formId}/interviews`);
  return response.data;
};

export const getQuestionExpectationSuggestion = async (
  formId: string,
  title: string,
  mcqOptions: string[]
): Promise<GetQuestionExpectationSuggestionResponse> => {
  const response = await axios.post(
    `/forms/${formId}/question-expectation-suggestion`,
    {
      title,
      mcqOptions,
    },
    {
      timeout: 60_000,
    }
  );
  return response.data;
};

export const updateInterviewFormIntro = async (
  formId: string,
  request: EditFormInterviewIntroRequest
): Promise<FormInterviewResponse> => {
  const response = await axios.put(
    `/forms/${formId}/interviews/intro`,
    request
  );
  return response.data.interview;
};

export const updateInterviewFormOutro = async (
  formId: string,
  request: EditFormInterviewOutroRequest
): Promise<FormInterviewResponse> => {
  const response = await axios.put(
    `/forms/${formId}/interviews/outro`,
    request
  );
  return response.data.interview;
};

export const addInterviewFormStep = async (
  formId: string,
  request: AddFormInterviewStepRequest
): Promise<FormInterviewStepResponse> => {
  const response = await axios.post(
    `/forms/${formId}/interviews/steps`,
    request
  );
  return response.data;
};

export const editInterviewFormStep = async (
  formId: string,
  stepId: string,
  request: EditFormInterviewStepRequest
): Promise<UpdateFormInterviewStepResponse> => {
  const response = await axios.put(
    `/forms/${formId}/interviews/steps/${stepId}`,
    request
  );
  return response.data;
};

export const deleteInterviewFormStep = async (
  formId: string,
  stepId: string
): Promise<FormInterviewResponse> => {
  const response = await axios.delete(
    `/forms/${formId}/interviews/steps/${stepId}`
  );
  return response.data.interview;
};

export const moveInterviewFormStep = async (
  formId: string,
  stepId: string,
  direction: 'up' | 'down'
): Promise<FormInterviewResponse> => {
  const response = await axios.put(
    `/forms/${formId}/interviews/steps/${stepId}/move`,
    {
      direction,
    }
  );
  return response.data.interview;
};
