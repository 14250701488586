import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { GetServerSideProps } from 'next';
import { setupLocale } from '@core/i18n';
import MetaHead from '@core/meta-head';
import { useAnalytics } from '@core/analytics';
import { ErrorType, mapErrorToErrorType } from '@features/common/errors/utils';
import { getFormPublicById } from '@core/services/forms';
import ErrorMapper from '@features/common/errors/ErrorMapper';
import { FormPublic } from '@core/@types/forms';
import FormApplication from '@features/form-application';

interface Props {
  form?: FormPublic;
  error?: ErrorType;
}

export default function Page({ form, error }: Props) {
  const analytics = useAnalytics();
  const { query } = useRouter();

  useEffect(() => {
    analytics.trackPage('FORM_APPLY');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {error && <ErrorMapper errorType={error} />}
      {form ? (
        <>
          <MetaHead
            removeBowldPrefix
            title={form.name}
            sharePreview={{
              title: form.name,
              description: form.name,
              imageUrl: form.shareImageUrl,
            }}
          />
          <FormApplication form={form} isPreview={query.preview != null} />
        </>
      ) : null}
    </>
  );
}

export const getServerSideProps: GetServerSideProps = async (context) => {
  try {
    const formId = context.query?.formId as string;
    const preview = context.query?.preview as unknown as boolean;
    const form = await getFormPublicById(formId, preview);

    return {
      props: {
        form,
        messages: await setupLocale(context.locale),
      },
    };
  } catch (err: any) {
    return {
      props: {
        error: mapErrorToErrorType(err),
        messages: await setupLocale(context.locale),
      },
    };
  }
};
