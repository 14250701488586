import { Box, Card } from '@mui/material';
import Video from '@core/ui/components/Video';

interface Props {
  videoUrl: string;
}

export default function FormApplicationIntroVideo({ videoUrl }: Props) {
  return (
    <Box
      sx={{
        width: 1,
      }}
    >
      <Card>
        <Video url={videoUrl} playsInline controls autoPlay />
      </Card>
    </Box>
  );
}
