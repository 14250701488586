import {
  InputAdornment,
  Link,
  Stack,
  Tooltip,
  Typography,
  alpha,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSnackbar } from '@core/ui/components/snackbar';
import { useLocales } from '@core/i18n';
import FormProvider, { RHFTextField } from '@core/ui/components/hook-form';
import FormItemContainer from '@core/ui/components/form-item-container';
import FormContainer from '@core/ui/components/form-container';
import { FormInterviewContactFieldType } from '@core/@types/forms';
import Iconify from '@core/ui/components/iconify/Iconify';
import { ROUTES } from '@core/config';
import { useFormApplication } from '../provider.form-application';

type FormValuesProps = {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  instagram: string;
  linkedIn: string;
  termsChecked: boolean;
  afterSubmit?: string;
};

export default function FormApplicationIntroContactForm() {
  const { translate } = useLocales();
  const { enqueueSnackbar } = useSnackbar();
  const { form, startApplication } = useFormApplication();

  const { contactFields } = form;

  const interviewDuration = Math.ceil(
    form.interviewContent.estimatedDurationInSeconds / 60
  );
  const questionCount = form.interviewContent.questions.length;

  const requiredMessage = translate('common.customErrors.requiredField');

  const firstNameSchema = Yup.string().trim(requiredMessage);
  const lastNameSchema = Yup.string().trim(requiredMessage);
  const emailSchema = Yup.string().email(
    translate('common.customErrors.invalidEmail')
  );
  const phoneNumberSchema = Yup.string().matches(
    /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-s./0-9]*$/g,
    {
      message: translate('common.customErrors.invalidPhoneNumber'),
      excludeEmptyString: true,
    }
  );
  const instagramSchema = Yup.string().matches(
    /^([A-Za-z0-9_](?:(?:[A-Za-z0-9_]|(?:\.(?!\.))){0,28}(?:[A-Za-z0-9_]))?)$/g,
    {
      message: translate('common.customErrors.invalidInstagram'),
      excludeEmptyString: true,
    }
  );

  const linkedInSchema = Yup.string().matches(
    /^(http(s)?:\/\/)?([\w]+\.)?linkedin\.com\/(pub|in|profile)\/([-a-zA-Z0-9]+)\/*/gm,
    {
      message: translate('common.customErrors.invalidLinkedIn'),
      excludeEmptyString: true,
    }
  );

  const addSchemaRequiredIfNeeded = (
    schema: Yup.AnySchema,
    field: FormInterviewContactFieldType
  ) =>
    field === 'required' ? schema.required(requiredMessage) : schema.optional();

  const FormSchema = Yup.object().shape({
    firstName: addSchemaRequiredIfNeeded(
      firstNameSchema,
      contactFields.firstName
    ),
    lastName: addSchemaRequiredIfNeeded(lastNameSchema, contactFields.lastName),
    email: addSchemaRequiredIfNeeded(emailSchema, contactFields.email),
    phoneNumber: addSchemaRequiredIfNeeded(
      phoneNumberSchema,
      contactFields.phoneNumber
    ),
    instagram: addSchemaRequiredIfNeeded(
      instagramSchema,
      contactFields.instagram
    ),
    linkedIn: addSchemaRequiredIfNeeded(linkedInSchema, contactFields.linkedIn),
  });

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(FormSchema),
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      instagram: '',
      linkedIn: '',
    },
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data: FormValuesProps) => {
    try {
      await startApplication(data);
    } catch (error: any) {
      enqueueSnackbar(translate('common.customErrors.unknownError'), {
        variant: 'error',
      });
    }
  };

  const formatTextFieldTitle = (
    key: string,
    field: FormInterviewContactFieldType
  ) => `${translate(key)}${field === 'required' ? ' *' : ''}`;

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <FormContainer loading={false}>
        {contactFields.firstName !== 'hidden' ? (
          <FormItemContainer
            title={formatTextFieldTitle(
              'features.formApplication.contactForm.fields.firstName.title',
              contactFields.firstName
            )}
            content={
              <RHFTextField
                id="firstName"
                name="firstName"
                placeholder="Tim"
                size="small"
                variant="outlined"
              />
            }
          />
        ) : null}

        {contactFields.lastName !== 'hidden' ? (
          <FormItemContainer
            title={formatTextFieldTitle(
              'features.formApplication.contactForm.fields.lastName.title',
              contactFields.lastName
            )}
            content={
              <RHFTextField
                id="lastName"
                name="lastName"
                placeholder="Cook"
                size="small"
                variant="outlined"
              />
            }
          />
        ) : null}

        {contactFields.email !== 'hidden' ? (
          <FormItemContainer
            title={formatTextFieldTitle(
              'features.formApplication.contactForm.fields.email.title',
              contactFields.email
            )}
            content={
              <RHFTextField
                id="email"
                name="email"
                type="email"
                placeholder="tim@apple.com"
                size="small"
                variant="outlined"
              />
            }
          />
        ) : null}

        {contactFields.phoneNumber !== 'hidden' ? (
          <FormItemContainer
            title={formatTextFieldTitle(
              'features.formApplication.contactForm.fields.phoneNumber.title',
              contactFields.phoneNumber
            )}
            content={
              <RHFTextField
                id="phoneNumber"
                name="phoneNumber"
                placeholder={translate(
                  'features.formApplication.contactForm.fields.phoneNumber.placeholder'
                )}
                size="small"
                variant="outlined"
              />
            }
          />
        ) : null}

        {contactFields.instagram !== 'hidden' ? (
          <FormItemContainer
            title={formatTextFieldTitle(
              'features.formApplication.contactForm.fields.instagram.title',
              contactFields.instagram
            )}
            content={
              <RHFTextField
                id="instagram"
                name="instagram"
                placeholder={translate(
                  'features.formApplication.contactForm.fields.instagram.placeholder'
                )}
                size="small"
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Typography variant="body2">
                        https://www.instagram.com/
                      </Typography>
                    </InputAdornment>
                  ),
                }}
              />
            }
          />
        ) : null}

        {contactFields.linkedIn !== 'hidden' ? (
          <FormItemContainer
            title={formatTextFieldTitle(
              'features.formApplication.contactForm.fields.linkedIn.title',
              contactFields.linkedIn
            )}
            content={
              <RHFTextField
                id="linkedIn"
                name="linkedIn"
                placeholder="https://www.linkedin.com/in/tim-cook"
                size="small"
                variant="outlined"
              />
            }
          />
        ) : null}

        <Stack spacing={1} pt={1}>
          <Stack
            spacing={0.5}
            direction="row"
            alignItems="center"
            justifyContent="center"
            pb={2}
          >
            <Typography
              variant="caption"
              fontSize={11}
              sx={{
                textAlign: 'center',
                wordWrap: 'break-word',
                whiteSpace: 'pre-wrap',
              }}
            >
              {translate(
                `features.formApplication.interviewInfo.content.${
                  questionCount > 1 ? 'plural' : 'singular'
                }`,
                {
                  questionCount,
                  duration: interviewDuration < 2 ? 2 : interviewDuration,
                }
              )}
            </Typography>

            <Tooltip
              title={
                <Typography
                  variant="body2"
                  sx={{ wordWrap: 'break-word', whiteSpace: 'pre-wrap' }}
                >
                  {translate(
                    'features.formApplication.interviewInfo.disclaimer'
                  )}
                </Typography>
              }
            >
              <Iconify icon="material-symbols:info" width={12} />
            </Tooltip>
          </Stack>

          <LoadingButton
            type="submit"
            loading={isSubmitting}
            sx={{
              backgroundColor: form.primaryColor,
              '&:hover': {
                backgroundColor: alpha(form.primaryColor, 0.8),
              },
              width: 1,
              color: 'white',
            }}
          >
            {translate('features.formApplication.contactForm.cta')}
          </LoadingButton>

          <Stack justifyContent="center">
            <Typography
              variant="body2"
              fontSize={10}
              color="text.secondary"
              textAlign="center"
            >
              {translate(
                'features.formApplication.contactForm.fields.termsCheck.title'
              )}
              <Link
                href={ROUTES.termsApplicants}
                target="_blank"
                sx={{
                  color: 'text.secondary',
                  textDecoration: 'underline',
                }}
              >
                {`${translate(
                  'features.formApplication.contactForm.fields.termsCheck.terms'
                )}`}
              </Link>
            </Typography>
          </Stack>
        </Stack>
      </FormContainer>
    </FormProvider>
  );
}
