import { ReactNode } from 'react';
import { Divider, Stack, Typography } from '@mui/material';
import { useFormApplication } from '../provider.form-application';
import FormApplicationIntroVideo from './video.intro.form-application';
import FormApplicationFormInfo from '../form-info.form-application';
import FormApplicationIntroContactForm from './contact-form.intro.form-application';
import { useLocales } from '@core/i18n';

export default function FormApplicationIntroDescription() {
  const { translate } = useLocales();
  const { form, isPreview } = useFormApplication();

  let content: ReactNode;
  if (form.isEnabled || isPreview) {
    content = (
      <>
        {form.intro?.videoUrl ? (
          <FormApplicationIntroVideo videoUrl={form.intro.videoUrl} />
        ) : null}

        {form.intro?.text ? (
          <Typography
            variant="body2"
            sx={{ wordWrap: 'break-word', whiteSpace: 'pre-wrap' }}
          >
            {form.intro.text}
          </Typography>
        ) : null}

        <FormApplicationIntroContactForm />
      </>
    );
  } else {
    content = (
      <Stack spacing={2}>
        <Divider></Divider>
        <Typography variant="overline">
          {translate('features.formApplication.disabled.title')}
        </Typography>

        <Typography
          variant="body2"
          sx={{ wordWrap: 'break-word', whiteSpace: 'pre-wrap' }}
        >
          {translate('features.formApplication.disabled.description')}
        </Typography>
      </Stack>
    );
  }

  return (
    <Stack
      spacing={2}
      sx={{
        p: 2,
      }}
    >
      <FormApplicationFormInfo mode="full" />

      {content}
    </Stack>
  );
}
