import { FormPublic } from '@core/@types/forms';
import Provider from './provider.form-application';
import FormApplicationContainer from './container.form-application';

interface Props {
  form: FormPublic;
  isPreview: boolean;
}

export default function FormApplication({ form, isPreview }: Props) {
  return (
    <Provider form={form} isPreview={isPreview}>
      <FormApplicationContainer />
    </Provider>
  );
}
