import { Divider, Stack, Typography } from '@mui/material';
import LocaleSwitcher from '@core/ui/components/locale-switcher/local-switcher';
import { useLocales } from '@core/i18n';
import { useFormApplication } from './provider.form-application';
import FormApplicationFormInfo from './form-info.form-application';

export default function FormApplicationHeader() {
  const { translate } = useLocales();
  const { form, isPreview, formData } = useFormApplication();

  return (
    <Stack width={1}>
      {isPreview ? (
        <Stack
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
            width: 1,
            background: form.primaryColor,
            p: 1,
            height: 32,
          }}
        >
          <Typography color="white" variant="caption" textAlign="center">
            {translate(`features.formApplication.previewMessage`)}
          </Typography>
        </Stack>
      ) : undefined}
      <Stack
        spacing={2}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        bgcolor={(theme) => theme.palette.common.white}
        width={1}
        px={2}
        py={1}
      >
        <FormApplicationFormInfo mode={formData ? 'full' : 'none'} />
        <LocaleSwitcher arrow="top-right" />
      </Stack>
      <Divider sx={{ width: 1 }} />
    </Stack>
  );
}
