import { useState } from 'react';
import { useRouter } from 'next/router';
import { MenuItem, Stack, IconButton } from '@mui/material';
import { useLocale } from 'next-intl';
import MenuPopover, {
  MenuPopoverArrowValue,
} from '@ui/components/menu-popover';
import Image from '@ui/components/image';
import { getLanguages } from '@core/i18n';

interface Props {
  arrow: MenuPopoverArrowValue;
}

export default function LocaleSwitcher({ arrow }: Props) {
  const locale = useLocale();
  const router = useRouter();

  const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null);

  const currentLanguage =
    getLanguages().find((l) => l.id === locale) || getLanguages()[0];

  const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  const handleChangeLang = (newLang: string) => {
    router.replace(router.asPath, undefined, {
      locale: newLang,
    });
    handleClosePopover();
  };

  return (
    <>
      <IconButton
        onClick={handleOpenPopover}
        sx={{
          width: 40,
          height: 40,
          ...(openPopover && {
            bgcolor: 'action.selected',
          }),
        }}
      >
        <Image src={currentLanguage.icon} alt={currentLanguage.name} />
      </IconButton>

      <MenuPopover
        open={openPopover}
        onClose={handleClosePopover}
        arrow={arrow}
      >
        <Stack spacing={0.75}>
          {getLanguages().map((language) => (
            <MenuItem
              key={language.id}
              selected={language.id === currentLanguage.id}
              onClick={() => handleChangeLang(language.id)}
            >
              <Image
                alt={language.name}
                src={language.icon}
                sx={{ width: 28, mr: 2 }}
              />

              {language.name}
            </MenuItem>
          ))}
        </Stack>
      </MenuPopover>
    </>
  );
}
